import type { NotificationData } from "@asap/shared";
import { generateNotificationText } from "@asap/shared";
import { chunk } from "lodash-es";
import type { DeepPartial } from "~/utils/deepPartial";
import { notificationColumns } from "~/utils/supabase.columns";
import type { Notification } from "~/utils/supabase.types";

export type NotificationFilterType = "business" | "talent" | "marketing" | "internal" | "all";

// Don't forget to update the notificationFilterMap when you add a new notification type
const notificationFilterMap: Record<NotificationData["type"], NotificationFilterType[]> = {
  mobile_app__talent_contract_signed: ["business"],
  mobile_app__timesheet_fully_approved: ["business"],
  mobile_app__talent_timesheet_reminder: ["business"],
  mobile_app__timesheet_rejected_by_client: ["business"],
  new_contract_to_process: ["business"],
  new_definitive_end_on_contract_to_process: ["business"],
  new_medical_checkup_request: ["talent"],
  failed_advance_payment_request: ["talent"],
  new_qualification_training_request: ["talent"],
  new_expense_claim_request_to_validate: ["internal"],
  new_expense_claim_request_to_process: ["internal"],
  new_professional_card_request: ["business"],
  talent_offboarding_needed: ["talent"],
  approved_recruitment_office_invoice_request: ["business"],
  refused_recruitment_office_invoice_request: ["business"],
  new_recruitment_office_invoice_request: ["business"],
  pending_recruitment_office_invoice_request: ["business"],
  done_recruitment_office_invoice_request: ["business"],
  new_contract_to_send: ["business"],
  new_onboarding_app_needed: ["talent"],
  new_advance_payment_request: ["talent"],
  failed_spayr_request: ["internal"],
  new_company_insurance_request: ["internal"],
  end_of_company_insurance_request: ["internal"],
  first_contract_signed_by_talent: ["business"],
  new_qualification_training_request_invoicing_document: ["talent"],
  new_qualification_training_request_certificate_document: ["talent"],
  cancel_medical_checkup_request_on_definitive_contract_end: ["talent"],
  failed_website_job_posting: ["marketing"],
  first_contract_signed_by_independent: ["business"],
  new_qualification_training_commitment: ["talent"],
  new_contract_document_pre_employment_declaration: ["business"],
  new_professional_card_request_document_uploaded: ["talent"],
  new_mission_to_post_on_job_boards: ["marketing"],
  archived_job_offer: ["marketing"],
  new_contract_signed: ["business"],
  new_contract_declined: ["business"],
  expiring_document: ["talent"],
  new_commercial_condition_created: ["business"],
  new_contract_to_validate: ["business"],
  medical_checkup_request_done: ["talent"],
  new_ppe_request: ["talent"],
  each_commercial_condition_validated: ["business"],
  job_offer_republish: ["marketing"],
  new_request_push_marketing: ["marketing"],
  turned_off_push_marketing: ["marketing"],
  new_work_accident_request: ["talent"],
  work_accident_request_done: ["talent"],
  medical_checkup_request_approved: ["talent"],
  qualification_training_request_approved: ["talent"],
};

// We omit the json data field cause it will be pick from the NotificationData type (and Json type is too complex for vue in some components)
export type NotificationCasted = Omit<Notification, "data"> &
  DeepPartial<NotificationData> & {
    filterType: NotificationFilterType[];
  };

const castNotification = (notification: Notification): NotificationCasted => {
  const filterType: NotificationFilterType[] = notificationFilterMap[notification.type] ?? [];
  return { ...notification, filterType: [...filterType, "all"] } as NotificationCasted;
};

const notifications = ref<NotificationCasted[]>([]);
const countUnread = ref(0);
const selectedFilter = ref<NotificationFilterType>("all");

export const useNotification = function () {
  const { authUser, userRoles } = useUserMe();
  const { addToastError } = useToast();
  const supabase = useSupabase();

  const loading = ref(true);

  const queryUserNotifications = async (): Promise<NotificationCasted[]> => {
    if (!authUser.value) {
      addToastError({
        description: "Impossible de récupérer ton compte, tente de te reconnecter.",
        title: "Erreur avec la connexion à Slack",
      });
      throw new Error("Impossible de récupérer ton compte, tente de te reconnecter.");
    }

    loading.value = true;

    const { data, error } = await supabase
      .from("notification")
      .select(notificationColumns)
      .or(`recipient_user.eq.${authUser.value.id},recipient_roles.ov.{${userRoles.value}}`)
      // .eq("is_read", false)
      .order("created_at", { ascending: false });

    loading.value = false;

    if (error) {
      addToastError({ description: "Impossible de récupérer les notifications." }, error);
      throw error;
    }

    return data.map(castNotification);
  };

  onMounted(async () => {
    notifications.value = await queryUserNotifications();
    countUnread.value = await countUserNotifications();
  });

  const countUserNotifications = async () => {
    if (!authUser.value) return 0;
    const { count, error } = await supabase
      .from("notification")
      .select(notificationColumns, { count: "exact", head: true })
      .or(`recipient_user.eq.${authUser.value.id},recipient_roles.ov.{${userRoles.value}}`)
      .eq("is_read", false);

    if (error) {
      addToastError({ description: "Impossible de récupérer le nombre de notifications." }, error);
      throw new Error(error.message);
    }

    return count ?? 0;
  };

  const markAsRead = async (notificationToUpdate: NotificationCasted) => {
    notifications.value = updateCollection(notifications.value, { ...notificationToUpdate, is_read: true });

    const { error } = await supabase
      .from("notification")
      .update({ is_read: true })
      .eq("id", notificationToUpdate.id)
      .select()
      .single();

    if (error) {
      addToastError({ description: "Impossible de trouver la page correspondante." });
      throw error;
    }

    countUnread.value = Math.max(0, countUnread.value - 1);
  };

  const navigateToLink = (notification: NotificationCasted) => {
    const url = notification.data?.navigateTo;

    if (!url) {
      addToastError({ description: "Impossible de trouver la page correspondante." });
      throw new Error("Impossible de trouver la page correspondante.");
    }

    const isExternal = url.startsWith("http");
    navigateTo(url, isExternal ? { external: true } : {});
  };

  const markAllAsRead = async () => {
    notifications.value = notifications.value.map((notification: any) => ({ ...notification, is_read: true }));
    // const notificationIds = notifications.value.map((notification) => notification.id);

    const chunkSize = 200; // 200 seems to be the limit.
    const chunks = chunk(notifications.value, chunkSize);

    await Promise.all(
      chunks.map(async (notifications: any) => {
        const notificationIds = notifications.map((notification: any) => notification.id);
        const { error } = await supabase.from("notification").update({ is_read: true }).in("id", notificationIds);
        if (error) {
          addToastError({ description: "Impossible de trouver la page correspondante." });
          throw error;
        }
      })
    );

    countUnread.value = 0;
  };

  const unreadNotifications = computed(() => notifications.value.filter((notification: any) => !notification.is_read));

  const isNotificationImportant = (notification: NotificationCasted) => {
    return userRoles.value.some((role) => {
      const notificationsForRole = importantNotifications[role];
      if (!notificationsForRole) return false;

      return notificationsForRole.includes(notification.type);
    });
  };

  const isNotificationBadged = (notification: NotificationCasted) => {
    return notification.data?.workspace === "external" || isNotificationImportant(notification);
  };

  const filterTabs: { filterType: NotificationFilterType; label: string; count: number }[] = [
    { filterType: "all", label: "Toutes", count: 0 },
    { filterType: "business", label: "Business", count: 0 },
    { filterType: "talent", label: "Talent", count: 0 },
    { filterType: "marketing", label: "Marketing", count: 0 },
    { filterType: "internal", label: "Interne", count: 0 },
  ];

  const computedFilterTabs = computed(() => {
    return filterTabs.map((tab) => {
      return {
        ...tab,
        count: notifications.value.filter((notification) => notification.filterType.includes(tab.filterType)).length,
      };
    });
  });

  const computedFilterTabsUnread = computed(() => {
    return filterTabs.map((tab) => {
      return {
        ...tab,
        count: unreadNotifications.value.filter((notification) => notification.filterType.includes(tab.filterType))
          .length,
      };
    });
  });

  const filteredNotifications = computed(() => {
    return notifications.value.filter((notification) => notification.filterType.includes(selectedFilter.value));
  });

  const filteredUnreadNotifications = computed(() => {
    return unreadNotifications.value.filter((notification) => notification.filterType.includes(selectedFilter.value));
  });

  return {
    notifications,
    unreadNotifications,
    countUnread,
    loading,
    markAsRead,
    markAllAsRead,
    navigateToLink,
    countUserNotifications,
    refresh: queryUserNotifications,
    generateNotificationText,
    isNotificationImportant,
    isNotificationBadged,
    computedFilterTabs,
    computedFilterTabsUnread,
    filteredNotifications,
    filteredUnreadNotifications,
    selectedFilter,
  };
};
